@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* hero-styles.css */
.hero-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  padding: 2rem;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.hero-content button {
  background-color: #000;
  color: #fff;
  padding: 0.8rem 1.5rem;
  border: none;
  cursor: pointer;
}

/* hero-styles.css */
.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 0 0 rgba(255, 165, 0, 0); /* Initial transparent shadow */
  transition: box-shadow 0.1s ease;
}

@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    text-align: center;
  }
  .hero-content {
    max-width: 90%;
    margin: 0 0 2rem;
  }
}

.hero-content {
  max-width: 40%;
  margin-right: 5rem;
  display: flex;
  flex-direction: column; /* This is the crucial line */
  align-items: flex-start; /* Align items to the left */
}

/* ... other styles */

.no-cc-text {
  margin-top: 5px;
  font-size: 0.75rem;
  color: #777;
}


.react-flow {
  /* Custom Variables */
  --xy-theme-selected: #000000;
  --xy-theme-hover: #C5C5C5;
  --xy-theme-edge-hover: black;
  --xy-theme-color-focus: #E8E8E8;

  /* Built-in Variables see https://reactflow.dev/learn/customization/theming */
  --xy-node-border-default: 1px solid #EDEDED;

  --xy-node-boxshadow-default: 
    0px 3.54px 4.55px 0px #00000005, 
    0px 3.54px 4.55px 0px #0000000D, 
    0px 0.51px 1.01px 0px #0000001A;

  --xy-node-border-radius-default: 8px;

  --xy-handle-background-color-default: #ffffff;
  --xy-handle-border-color-default: #AAAAAA;

  --xy-edge-label-color-default: #505050;
}

.react-flow.dark { 
  --xy-node-boxshadow-default: 
  0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.05), /* light shadow */
  0px 3.54px 4.55px 0px rgba(255, 255, 255, 0.13), /* medium shadow */
  0px 0.51px 1.01px 0px rgba(255, 255, 255, 0.2);  /* smallest shadow */  
  --xy-theme-color-focus: #535353;
}

/* Customizing Default Theming */

.react-flow__node {
  box-shadow: var(--xy-node-boxshadow-default);
  border-radius: var(--xy-node-border-radius-default);
  background-color: var(--xy-node-background-color-default);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  flex-direction: column;
  border: var(--xy-node-border-default);
  color: var(--xy-node-color, var(--xy-node-color-default));
}

.react-flow__node.selectable:focus {
  box-shadow: 0px 0px 0px 4px var(--xy-theme-color-focus);
  border-color: #d9d9d9;
}

.react-flow__node.selectable:focus:active {
  box-shadow: var(--xy-node-boxshadow-default);
}


.react-flow__node.selectable:hover, 
.react-flow__node.draggable:hover {
  border-color: var(--xy-theme-hover);
}

.react-flow__node.selectable.selected {
  border-color: var(--xy-theme-selected);
  box-shadow: var(--xy-node-boxshadow-default);
}

.react-flow__node-group {
  background-color: rgba(207, 182, 255, 0.4);
  border-color: #9E86ED;
}

.react-flow__edge.selectable:hover .react-flow__edge-path,
.react-flow__edge.selectable.selected .react-flow__edge-path {
  stroke: var(--xy-theme-edge-hover);
}

.react-flow__handle {
  background-color: var(--xy-handle-background-color-default);
}

.react-flow__handle.connectionindicator:hover {
  pointer-events: all;
  border-color: var(--xy-theme-edge-hover);
  background-color: white;
}

.react-flow__handle.connectionindicator:focus , 
.react-flow__handle.connectingfrom,
.react-flow__handle.connectingto {
  border-color: var(--xy-theme-edge-hover);
}

.react-flow__node-resizer {
  border-radius: 0;
  border: none;
}

.react-flow__resize-control.handle {
  background-color: #ffffff;
  border-color: #000000;
  border-radius: 0;
  width: 5px;
  height: 5px;
}
